import { proxy } from 'valtio';
import { adForTVChart2 } from '~/trades/indicators/futuresai/ad';
import { bs0ForTVChart2 } from '~/trades/indicators/futuresai/bs0';
import { bs1ForTVChart2 } from '~/trades/indicators/futuresai/bs1';
import { bs2ForTVChart2 } from '~/trades/indicators/futuresai/bs2';
import { ivForTVChart2 } from '~/trades/indicators/futuresai/iv';
import { opmfForTVChart2 } from '~/trades/indicators/futuresai/opmf';
import { opofForTVChart2 } from '~/trades/indicators/futuresai/opof';
import { tiForTVChart2 } from '~/trades/indicators/futuresai/ti';
import { timeValueForTVChart2 } from '~/trades/indicators/futuresai/time_value';
import { estimatedVolumeForTVChart2 } from '~/trades/indicators/futuresai/estimated_volume';
import { SignalWallId } from '~/modules/signal-wall/SignalWallId';
import { FuiSignals } from '~/pages/heineken_template/components/FuiSignals';
import { FuiSymbolWatch } from '~/pages/heineken_template/components/FuiSymbolWatch';
import { foreigner_txf } from '~/trades/indicators/futuresai/futures_chips/foreigner_txf';
import { foreigner_twse } from '~/trades/indicators/futuresai/futures_chips/foreigner_twse';
import { dealer_twse } from '~/trades/indicators/futuresai/futures_chips/dealer_twse';
import { dealer_txf } from '~/trades/indicators/futuresai/futures_chips/dealer_txf';
import { retail_oi } from '~/trades/indicators/futuresai/futures_chips/retail_oi';
import { eurex_main_trend } from '~/trades/indicators/eurex/eurex_main_trend';
import { eurex_power } from '~/trades/indicators/eurex/eurex_power';
import { eurex_trend_line } from '~/trades/indicators/eurex/eurex_trend_line';
import { eurexIndicatorValueProvider } from '~/trades/indicators/example/eurexIndicatorValueProvider';
import { stock_dealer } from '~/trades/indicators/futuresai/stock_chips/stock_dealer';
import { stock_foreigner } from '~/trades/indicators/futuresai/stock_chips/stock_foreigner';
import { stock_trust } from '~/trades/indicators/futuresai/stock_chips/stock_trust';
import { double_moving_average } from '~/trades/indicators/defaults/double_moving_average';
import { stock_tdcc_big } from '~/trades/indicators/futuresai/stock_chips/stock_tdcc_big';
import { stock_tdcc_small } from '~/trades/indicators/futuresai/stock_chips/stock_tdcc_small';
import { stock_tdcc_total_people } from '~/trades/indicators/futuresai/stock_chips/stock_tdcc_total_people';
const stockIndicators = [bs0ForTVChart2, bs1ForTVChart2, bs2ForTVChart2, estimatedVolumeForTVChart2];
const strategies = {
    quotePage: {
        all: {
            displayName: '全部指標',
            indicators: [
                bs0ForTVChart2,
                bs1ForTVChart2,
                tiForTVChart2,
                opmfForTVChart2,
                opofForTVChart2,
                ivForTVChart2,
                timeValueForTVChart2,
                adForTVChart2,
            ],
            interval: 5,
            symbol: 'TX-1',
            panesRatio: 37.5,
        },
        txChips: {
            displayName: '台指籌碼',
            indicators: [bs0ForTVChart2, bs1ForTVChart2, tiForTVChart2],
            interval: 5,
            symbol: 'TX-1',
            panesRatio: 60,
        },
        options: {
            displayName: '選擇權分析',
            indicators: [opmfForTVChart2, opofForTVChart2, ivForTVChart2, timeValueForTVChart2],
            interval: 5,
            symbol: 'TX-1',
            panesRatio: 50,
        },
        stock: {
            displayName: '股票籌碼',
            indicators: [
                double_moving_average,
                stock_foreigner,
                stock_dealer,
                stock_trust,
                stock_tdcc_big,
                stock_tdcc_small,
                stock_tdcc_total_people,
            ],
            interval: '1D',
            panesRatio: 35,
        },
        none: {
            displayName: '移除指標',
            indicators: [],
            panesRatio: 100,
        },
    },
    signalWallPage: {
        defaults: {
            displayName: '訊號牆策略指標',
            symbol: 'TX-1',
            interval: 'D',
            indicators: [],
        },
    },
    /** 策略組，`daily-chips` 頁面專屬 */
    dailyChipsPage: {
        /** 外資 */
        foreignInvestment: {
            displayName: '外資',
            indicators: [foreigner_txf, foreigner_twse],
            symbol: 'TSEA',
            interval: '1D',
            panesRatio: 50,
        },
        /** 自營 */
        selfOperation: {
            displayName: '自營',
            indicators: [dealer_twse, dealer_txf],
            symbol: 'TSEA',
            interval: '1D',
            panesRatio: 50,
        },
        preloadIndicators: {
            displayName: '其它獨立指標',
            indicators: [retail_oi],
        },
    },
    /** 策略組，`crypto` 頁面專屬 */
    crypto: {
        /** crypto */
        cryptoOperation: {
            displayName: '-',
            indicators: [eurexIndicatorValueProvider, eurex_trend_line, eurex_main_trend, eurex_power],
            symbol: 'BTCUSDT',
            interval: '60',
            panesRatio: 72,
        },
    },
    usStock: {
        /** usStock: */
        usStockOperation: {
            displayName: '-',
            indicators: [eurexIndicatorValueProvider, eurex_trend_line, eurex_main_trend, eurex_power],
            symbol: 'TSM',
            interval: '60',
            panesRatio: 72,
        },
    },
};
/** Template 頁面使用的全局 rootStore */
export const futuresai_store = proxy({
    mySymbols: new FuiSymbolWatch({
        displayName: '自選股',
        groupName: 'futuresai_stock_group_1',
    }),
    strategies: strategies,
    signalrQuote: {
        quotePage: {
            /** 台股報價磚 */
            twStock: [
                { symbol: 'TSEA', indicators: [estimatedVolumeForTVChart2] },
                { symbol: 'OTCA', indicators: [estimatedVolumeForTVChart2] },
                { symbol: 'TSE13', indicators: [] },
                { symbol: 'TSE17', indicators: [] },
                { symbol: 'TAIWANVIX', indicators: [] },
                { symbol: '2330', indicators: stockIndicators },
                { symbol: '2317', indicators: stockIndicators },
                { symbol: '2454', indicators: stockIndicators },
                { symbol: '2308', indicators: stockIndicators },
                { symbol: '2382', indicators: stockIndicators },
                { symbol: '2881', indicators: stockIndicators },
            ],
            /** 期貨報價 */
            futures: [
                {
                    symbol: 'TX-1',
                    getStrategy: () => {
                        return strategies.quotePage.all;
                    },
                },
                {
                    symbol: 'TWN-1',
                    getStrategy: () => {
                        return strategies.quotePage.none;
                    },
                },
                {
                    symbol: 'YM-1',
                    getStrategy: () => {
                        return strategies.quotePage.none;
                    },
                },
                {
                    symbol: 'NQ-1',
                    getStrategy: () => {
                        return strategies.quotePage.none;
                    },
                },
                {
                    symbol: 'ES-1',
                    getStrategy: () => {
                        return strategies.quotePage.none;
                    },
                },
                {
                    symbol: 'RTY-1',
                    getStrategy: () => {
                        return strategies.quotePage.none;
                    },
                },
                {
                    symbol: 'FDX-1',
                    getStrategy: () => {
                        return strategies.quotePage.none;
                    },
                },
                {
                    symbol: 'NK-1',
                    getStrategy: () => {
                        return strategies.quotePage.none;
                    },
                },
                {
                    symbol: 'HSI-1',
                    getStrategy: () => {
                        return strategies.quotePage.none;
                    },
                },
                {
                    symbol: 'CN-1',
                    getStrategy: () => {
                        return strategies.quotePage.none;
                    },
                },
                {
                    symbol: 'CL-1',
                    getStrategy: () => {
                        return strategies.quotePage.none;
                    },
                },
                {
                    symbol: 'GC-1',
                    getStrategy: () => {
                        return strategies.quotePage.none;
                    },
                },
                {
                    symbol: 'DX-1',
                    getStrategy: () => {
                        return strategies.quotePage.none;
                    },
                },
            ],
        },
    },
    signalWallPage: {
        signals: new FuiSignals({
            configs: [
                {
                    query: SignalWallId.盤中突破日內高點,
                    displayName: '盤中突破日內高點',
                    enabled: true,
                },
                {
                    query: SignalWallId.盤中突破日內低點,
                    displayName: '盤中突破日內低點',
                    enabled: true,
                },
                {
                    query: SignalWallId.盤中高預估周轉率,
                    displayName: '盤中高預估周轉率',
                    enabled: true,
                },
                {
                    query: SignalWallId.盤中高預估量比,
                    displayName: '盤中高預估量比',
                    enabled: true,
                },
                {
                    query: SignalWallId.盤中創五日新高,
                    displayName: '盤中創五日新高',
                    enabled: true,
                },
                {
                    query: SignalWallId.盤中創五日新低,
                    displayName: '盤中創五日新低',
                    enabled: true,
                },
                {
                    // queryId: `(qty_alert|RT_futuresai_0\\d)`,
                    query: SignalWallId.大單成交,
                    displayName: '大單成交',
                    enabled: true,
                },
            ],
        }),
    },
});
