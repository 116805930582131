import { arrayMoveMutable } from 'array-move';
import { findIndex, map } from 'lodash';
import { Fragment, useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { proxy, ref, snapshot, useSnapshot } from 'valtio';
import { FrGa } from '~/modules/SDK/FrGa/FrGa';
import { debugAPI } from '~/modules/SDK/debug/debugAPI';
import { getFirebaseStore } from '~/modules/SDK/firebase/getFirebase';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
import { component } from '~/utils/component';
export class FuiSymbolWatch {
    store;
    firestore = getFirebaseStore();
    /** 一次性撈取遠端值並更新 state */
    fetchSymbols = this.autoLoadingState(async () => {
        const doc = await this.doc().get();
        const data = doc.data();
        this.store.symbols = data[this.store.groupName] || [];
        return this.store.symbols;
    });
    removeSymbol = this.autoLoadingState(async (symbol) => {
        debugAPI.FuiSymbolWatch.log(`removeSymbol() awaiting`, { symbol });
        const before = [...this.store.symbols];
        const toDeleteAtIndex = findIndex(this.store.symbols, element => element === symbol);
        this.store.symbols.splice(toDeleteAtIndex, 1);
        await this.doc().update({
            [this.store.groupName]: this.store.symbols,
        });
        FrGa.event({
            自選股__移除自選股: {
                groupName: this.store.groupName,
                replace: this.store.symbols,
                target: [symbol],
            },
        });
        debugAPI.FuiSymbolWatch.log(`removeSymbol() ok`, {
            before,
            after: this.store.symbols,
            要移除的: symbol,
        });
    });
    insertSymbol = this.autoLoadingState(async (symbol) => {
        debugAPI.FuiSymbolWatch.log(`insertSymbol() awaiting`, { symbol });
        if (!symbol) {
            console.warn(`不可加入空值`, `.insertSymbol()`, {
                當前: snapshot(this.store.symbols),
                要加入的: symbol,
                limit: this.store.limit,
            });
            return;
        }
        const limitAllow = this.store.symbols.length <= this.store.limit;
        const hasDuplicated = this.store.symbols.includes(symbol);
        if (!limitAllow) {
            console.warn(`已達上限`, `.insertSymbol()`, {
                當前: snapshot(this.store.symbols),
                要加入的: symbol,
                limit: this.store.limit,
            });
            return;
        }
        if (hasDuplicated) {
            console.warn(`重複的`, `.insertSymbol()`, {
                當前: snapshot(this.store.symbols),
                要加入的: symbol,
                limit: this.store.limit,
            });
            return;
        }
        const savedData = {
            [this.store.groupName]: this.store.symbols,
            // [this.store.groupName]: this.firestore.FieldValue.arrayUnion(symbol),
        };
        this.store.symbols.push(symbol);
        await this.doc().update(savedData);
        FrGa.event({
            自選股__新增自選股: {
                groupName: this.store.groupName,
                target: [symbol],
            },
        });
        debugAPI.FuiSymbolWatch.log(`insertSymbol() ok`, { savedData });
    });
    /** 將前端 UI 排序的 order 向後端存檔 */
    upsertOrder = this.autoLoadingState(async () => {
        debugAPI.FuiSymbolWatch.log(`upsertOrder() awaiting`);
        const savedData = {
            [this.store.groupName]: this.store.symbols,
        };
        await this.doc().update(savedData);
        debugAPI.FuiSymbolWatch.log(`upsertOrder() ok`, { savedData });
    });
    constructor(options) {
        this.store = proxy({
            limit: 1000,
            /** 觀察中商品(們) */
            symbols: [],
            ...options,
            /** 是否與後端溝通中 */
            loading: false,
        });
        debugAPI.FuiSymbolWatch.log(`new()`, { store: this.store });
    }
    doc() {
        if (!this.docPath) {
            console.error('尚未登入', { useMeStore: useMeStore.getState() });
            throw new Error('尚未登入');
        }
        return this.firestore().collection(this.collectionPath).doc(this.docPath);
    }
    moveOrder(fromArrayIndex, toArrayIndex) {
        const before = [...this.store.symbols];
        arrayMoveMutable(this.store.symbols, fromArrayIndex, toArrayIndex);
        debugAPI.FuiSymbolWatch.log(`moveOrder()`, {
            before,
            after: this.store.symbols,
        });
    }
    /**
     * 訂閱遠端值改變，並自動連續更新 state
     *
     * ### 需要清 memory leak
     *
     * - 返回 `function unsub()` 需要用它來清 memory leak
     */
    watchSymbols() {
        return this.doc().onSnapshot(doc => {
            const data = doc.data();
            this.store.symbols = data[this.store.groupName] || [];
        });
    }
    DragDropContext = ref(component(props => {
        return (<DragDropContext onDragEnd={result => {
                if (result.destination?.index !== undefined) {
                    this.moveOrder(result.source.index, result.destination.index);
                    this.upsertOrder();
                }
            }}>
          {props.children}
        </DragDropContext>);
    }));
    Droppable = ref(component(props => {
        return (<Droppable droppableId={this.store.displayName}>
          {provided => {
                return (<div className={props.className} ref={provided.innerRef} {...provided.droppableProps}>
                {props.children}
                {provided.placeholder}
              </div>);
            }}
        </Droppable>);
    }));
    DraggableList = ref(component(props => {
        const uid = useMeStore(state => state.meUserState?.uid);
        const symbols = useSnapshot(this.store).symbols;
        /**
         * - 使列表同步 state
         * - 使用戶在某tab頁籤操作，能同步 state 到如果用戶有多個tab頁籤
         */
        useEffect(() => {
            if (!uid)
                return;
            const unsub = this.watchSymbols();
            return unsub;
        }, [uid]);
        return (<Fragment>
          {map(symbols, (symbol, index) => {
                return (<Draggable key={symbol} draggableId={symbol} index={index}>
                {provided => {
                        return (<div className={props.className} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      {props.children({ symbol })}
                    </div>);
                    }}
              </Draggable>);
            })}
        </Fragment>);
    }));
    collectionPath = 'symbol_watch_list';
    get docPath() {
        return useMeStore.getState().meUserState?.uid;
    }
    autoLoadingState(func) {
        const wrappingFunc = (async (...args) => {
            this.store.loading = true;
            const data = await func(...args);
            this.store.loading = false;
            return data;
        });
        return wrappingFunc;
    }
}
